import React, { useRef, useEffect, useContext } from 'react';
import * as d3 from 'd3';
import SEO from '../components/SEO';
import { DimensionContext } from '../components/ResponsiveWrapper';
import InfoBox from '../components/InfoBox';
import { useDataset } from '../components/DataFetcher';

const HappyPath = () => {
  const canvasRef = useRef(null);
  const dimensions = useContext(DimensionContext);

  const { data, status, error } = useDataset();

  useEffect(() => {
    if (status !== 'success' || !data) {
      return;
    }

    const width = dimensions.width; // Full width from DimensionContext
    const height = dimensions.height;
    const margin = 50;

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;

    // Helper function to strip page from events (removing `@/page`)
    const stripPageFromEvent = (path) =>
      path.map((node) => node.split('@')[0].trim());

    // Parse the data from the database and remove pages
    const mainHappyPath = stripPageFromEvent(
      data.HappyPath ? JSON.parse(data.HappyPath) : []
    );
    const disjointedPaths = data.DisjointedPaths
      ? JSON.parse(data.DisjointedPaths).map(stripPageFromEvent)
      : [];

    // Combine all paths, sort by length, and ensure the main Happy Path is in the center
    const allPaths = [mainHappyPath, ...disjointedPaths].sort(
      (a, b) => a.length - b.length
    );

    const startNode = mainHappyPath[0];
    const endNode = mainHappyPath[mainHappyPath.length - 1];
    const pathsWithoutStartEnd = allPaths.map((path) => path.slice(1, -1));
    const middlePath = [startNode, ...pathsWithoutStartEnd[0], endNode];

    // Determine number of rows and place the main Happy Path in the center row
    const numRows = allPaths.length;
    const middleRowIndex = Math.floor(numRows / 2);
    const rows = new Array(numRows).fill(null).map(() => []);
    rows[middleRowIndex] = middlePath;

    // Distribute other paths around the Happy Path, shortest inside and longest outside
    let topIndex = middleRowIndex - 1;
    let bottomIndex = middleRowIndex + 1;
    for (let i = 1; i < allPaths.length; i++) {
      const path = allPaths[i];
      const rowIndex = i % 2 === 0 ? topIndex-- : bottomIndex++;
      rows[rowIndex] = path.slice(1, -1);
    }

    // Define scales with increased horizontal box width
    const baseNodeWidth = 150; // Increased width for better text fit
    const baseNodeHeight = 50;
    const xScale = d3
      .scaleLinear()
      .domain([0, Math.max(...rows.map((row) => row.length)) - 1])
      .range([margin, width - margin - baseNodeWidth]);
    const yScale = d3
      .scaleLinear()
      .domain([0, rows.length - 1])
      .range([margin, height - margin - baseNodeHeight]);

    // Coordinates of the common start and end nodes on the middle row
    const commonStartX = xScale(0);
    const commonStartY = yScale(middleRowIndex);
    const commonEndX = xScale(Math.max(...rows.map((row) => row.length)) - 1);

    // Draw nodes and lines
    rows.forEach((row, rowIndex) => {
      const emptySpaces = Math.max(...rows.map((r) => r.length)) - row.length;
      const startPosition = emptySpaces / 2;

      // Adjust box size based on row proximity to center (shorter paths have larger boxes)
      const boxScale = 1 - Math.abs(rowIndex - middleRowIndex) * 0.1;
      const nodeWidth = baseNodeWidth * boxScale;
      const nodeHeight = baseNodeHeight * boxScale;

      row.forEach((node, nodeIndex) => {
        const adjustedIndex = startPosition + nodeIndex;

        if (
          (node === startNode || node === endNode) &&
          rowIndex !== middleRowIndex
        )
          return;

        let x = xScale(adjustedIndex);
        const y = yScale(rowIndex);

        if (node === startNode) {
          x = xScale(0);
        } else if (node === endNode) {
          x = xScale(Math.max(...rows.map((row) => row.length)) - 1);
        }

        context.lineWidth = 3;
        context.strokeStyle = 'gray';
        context.setLineDash([10, 5]);

        // Draw horizontal lines connecting nodes within the path
        if (
          nodeIndex > 0 &&
          node !== endNode &&
          row[nodeIndex - 1] !== startNode
        ) {
          context.beginPath();
          context.moveTo(
            xScale(startPosition + nodeIndex - 1) + nodeWidth / 2,
            y + nodeHeight / 2
          );
          context.lineTo(x + nodeWidth / 2, y + nodeHeight / 2);
          context.stroke();
        }

        // Draw vertical lines connecting to common start and end nodes
        if (rowIndex !== middleRowIndex) {
          if (nodeIndex === 0) {
            context.beginPath();
            context.moveTo(commonStartX + nodeWidth, commonStartY + nodeHeight / 2);
            context.lineTo(x, y + nodeHeight / 2);
            context.stroke();
          }

          if (nodeIndex === row.length - 1) {
            context.beginPath();
            context.moveTo(x + nodeWidth, y + nodeHeight / 2);
            context.lineTo(commonEndX, commonStartY + nodeHeight / 2);
            context.stroke();
          }
        }
      });
    });

    // Draw the main happy path line
    if (middlePath.length > 2) {
      context.beginPath();
      context.moveTo(commonStartX + baseNodeWidth, commonStartY + baseNodeHeight / 2);
      context.lineTo(commonEndX, commonStartY + baseNodeHeight / 2);
      context.lineWidth = 10;
      context.strokeStyle = '#ebb844';
      context.setLineDash([20, 25]);
      context.stroke();
    }

    // Draw nodes (boxes with labels and tooltips)
    rows.forEach((row, rowIndex) => {
      const emptySpaces = Math.max(...rows.map((r) => r.length)) - row.length;
      const startPosition = emptySpaces / 2;

      // Adjust box size based on row proximity to center
      const boxScale = 1 - Math.abs(rowIndex - middleRowIndex) * 0.1;
      const nodeWidth = baseNodeWidth * boxScale;
      const nodeHeight = baseNodeHeight * boxScale;

      row.forEach((node, nodeIndex) => {
        const adjustedIndex = startPosition + nodeIndex;

        if (
          (node === startNode || node === endNode) &&
          rowIndex !== middleRowIndex
        )
          return;

        let x = xScale(adjustedIndex);
        const y = yScale(rowIndex);

        if (node === startNode) {
          x = xScale(0);
        } else if (node === endNode) {
          x = xScale(Math.max(...rows.map((row) => row.length)) - 1);
        }

        context.setLineDash([]);
        context.beginPath();
        context.rect(x, y, nodeWidth, nodeHeight);

        context.fillStyle = 'white';
        context.fill();

        context.lineWidth = 1;
        context.strokeStyle = '#958e86';
        context.stroke();

        // Clip text inside the box
        context.save();
        context.rect(x, y, nodeWidth, nodeHeight);
        context.clip();

        context.font = `${Math.min(12 * boxScale, 12)}px Arial`;
        context.fillStyle = 'black';

        // Render text within padding limits, clipping at box boundary without ellipsis
        context.fillText(node, x + 10, y + nodeHeight / 2 + 5);
        context.restore();

        // Instant Tooltip for full box content
        canvasRef.current.addEventListener('mousemove', (e) => {
          const rect = canvas.getBoundingClientRect();
          const mouseX = e.clientX - rect.left;
          const mouseY = e.clientY - rect.top;

          if (
            mouseX >= x &&
            mouseX <= x + nodeWidth &&
            mouseY >= y &&
            mouseY <= y + nodeHeight
          ) {
            canvas.title = node; // Instant tooltip shows full content of the box
          } else if (canvas.title === node) {
            canvas.title = ''; // Remove tooltip when not hovering
          }
        });
      });
    });
  }, [data, dimensions, status]);

  // Render the component
  return (
    <div className="p-4 bg-white bg-opacity-80">
      <h1 className="h1 banner">Happy Path</h1>
      <InfoBox
        title={"YourBiz's Happy Path"}
        description={
          'The shortest route, that the most people take, to your most desired outcome'
        }
      />
      {status === 'loading' && <div>Loading...</div>}
      {status === 'error' && <div>Error: {error.message}</div>}
      {status === 'success' && <canvas ref={canvasRef}></canvas>}
      <div>
        <SEO
          title="Happy Path"
          description="The fastest routes through your product to get to activation."
          url="/happypath"
        />
      </div>
    </div>
  );
};

export default HappyPath;



// import React, { useRef, useEffect, useContext } from 'react';
// import * as d3 from 'd3';
// import SEO from '../components/SEO';
// import { DimensionContext } from '../components/ResponsiveWrapper';
// import InfoBox from '../components/InfoBox';
// import { useDataset } from '../components/DataFetcher';

// const HappyPath = () => {
//   const canvasRef = useRef(null);
//   const dimensions = useContext(DimensionContext);

//   const { data, status, error } = useDataset();

//   useEffect(() => {
//     if (status !== 'success' || !data) {
//       return;
//     }

//     const width = dimensions.width;
//     const height = dimensions.height;
//     const margin = 50;

//     const canvas = canvasRef.current;
//     const context = canvas.getContext('2d');
//     canvas.width = width;
//     canvas.height = height;

//     // Helper function to strip page from events (removing `@/page`)
//     const stripPageFromEvent = (path) =>
//       path.map((node) => node.split('@')[0].trim());

//     // Parse the data from the database and remove pages
//     const mainHappyPath = stripPageFromEvent(
//       data.HappyPath ? JSON.parse(data.HappyPath) : []
//     );
//     const disjointedPaths = data.DisjointedPaths
//       ? JSON.parse(data.DisjointedPaths).map(stripPageFromEvent)
//       : [];

//     // Combine all paths, sort by length, and ensure the main Happy Path is in the center
//     const allPaths = [mainHappyPath, ...disjointedPaths].sort(
//       (a, b) => a.length - b.length
//     );

//     const startNode = mainHappyPath[0];
//     const endNode = mainHappyPath[mainHappyPath.length - 1];
//     const pathsWithoutStartEnd = allPaths.map((path) => path.slice(1, -1));
//     const middlePath = [startNode, ...pathsWithoutStartEnd[0], endNode];

//     // Determine number of rows and place the main Happy Path in the center row
//     const numRows = allPaths.length;
//     const middleRowIndex = Math.floor(numRows / 2);
//     const rows = new Array(numRows).fill(null).map(() => []);
//     rows[middleRowIndex] = middlePath;

//     // Distribute other paths around the Happy Path, shortest inside and longest outside
//     let topIndex = middleRowIndex - 1;
//     let bottomIndex = middleRowIndex + 1;
//     for (let i = 1; i < allPaths.length; i++) {
//       const path = allPaths[i];
//       const rowIndex = i % 2 === 0 ? topIndex-- : bottomIndex++;
//       rows[rowIndex] = path.slice(1, -1);
//     }

//     // Determine max nodes for scaling and dynamic box size scaling
//     const maxNodes = Math.max(
//       mainHappyPath.length,
//       ...disjointedPaths.map((p) => p.length)
//     );

//     // Define scales
//     const baseNodeWidth = 125;
//     const baseNodeHeight = 50;
//     const xScale = d3
//       .scaleLinear()
//       .domain([0, maxNodes - 1])
//       .range([margin, width - margin - baseNodeWidth]);
//     const yScale = d3
//       .scaleLinear()
//       .domain([0, rows.length - 1])
//       .range([margin, height - margin - baseNodeHeight]);

//     // Coordinates of the common start and end nodes on the middle row
//     const commonStartX = xScale(0);
//     const commonStartY = yScale(middleRowIndex);
//     const commonEndX = xScale(maxNodes - 1);

//     // Draw nodes and lines
//     rows.forEach((row, rowIndex) => {
//       const emptySpaces = maxNodes - row.length;
//       const startPosition = emptySpaces / 2;

//       // Adjust box size based on row proximity to center (shorter paths have larger boxes)
//       const boxScale = 1 - Math.abs(rowIndex - middleRowIndex) * 0.1;
//       const nodeWidth = baseNodeWidth * boxScale;
//       const nodeHeight = baseNodeHeight * boxScale;

//       row.forEach((node, nodeIndex) => {
//         const adjustedIndex = startPosition + nodeIndex;

//         if (
//           (node === startNode || node === endNode) &&
//           rowIndex !== middleRowIndex
//         )
//           return;

//         let x = xScale(adjustedIndex);
//         const y = yScale(rowIndex);

//         if (node === startNode) {
//           x = xScale(0);
//         } else if (node === endNode) {
//           x = xScale(maxNodes - 1);
//         }

//         context.lineWidth = 3;
//         context.strokeStyle = 'gray';
//         context.setLineDash([10, 5]);

//         // Draw horizontal lines connecting nodes within the path
//         if (
//           nodeIndex > 0 &&
//           node !== endNode &&
//           row[nodeIndex - 1] !== startNode
//         ) {
//           context.beginPath();
//           context.moveTo(
//             xScale(startPosition + nodeIndex - 1) + nodeWidth / 2,
//             y + nodeHeight / 2
//           );
//           context.lineTo(x + nodeWidth / 2, y + nodeHeight / 2);
//           context.stroke();
//         }

//         // Draw vertical lines connecting to common start and end nodes
//         if (rowIndex !== middleRowIndex) {
//           if (nodeIndex === 0) {
//             context.beginPath();
//             context.moveTo(commonStartX + nodeWidth, commonStartY + nodeHeight / 2);
//             context.lineTo(x, y + nodeHeight / 2);
//             context.stroke();
//           }

//           if (nodeIndex === row.length - 1) {
//             context.beginPath();
//             context.moveTo(x + nodeWidth, y + nodeHeight / 2);
//             context.lineTo(commonEndX, commonStartY + nodeHeight / 2);
//             context.stroke();
//           }
//         }
//       });
//     });

//     // Draw the main happy path line
//     if (middlePath.length > 2) {
//       context.beginPath();
//       context.moveTo(commonStartX + baseNodeWidth, commonStartY + baseNodeHeight / 2);
//       context.lineTo(commonEndX, commonStartY + baseNodeHeight / 2);
//       context.lineWidth = 10;
//       context.strokeStyle = '#ebb844';
//       context.setLineDash([20, 25]);
//       context.stroke();
//     }

//     // Draw nodes (boxes with labels and tooltips)
//     rows.forEach((row, rowIndex) => {
//       const emptySpaces = maxNodes - row.length;
//       const startPosition = emptySpaces / 2;

//       // Adjust box size based on row proximity to center
//       const boxScale = 1 - Math.abs(rowIndex - middleRowIndex) * 0.1;
//       const nodeWidth = baseNodeWidth * boxScale;
//       const nodeHeight = baseNodeHeight * boxScale;

//       row.forEach((node, nodeIndex) => {
//         const adjustedIndex = startPosition + nodeIndex;

//         if (
//           (node === startNode || node === endNode) &&
//           rowIndex !== middleRowIndex
//         )
//           return;

//         let x = xScale(adjustedIndex);
//         const y = yScale(rowIndex);

//         if (node === startNode) {
//           x = xScale(0);
//         } else if (node === endNode) {
//           x = xScale(maxNodes - 1);
//         }

//         context.setLineDash([]);
//         context.beginPath();
//         context.rect(x, y, nodeWidth, nodeHeight);

//         context.fillStyle = 'white';
//         context.fill();

//         context.lineWidth = 1;
//         context.strokeStyle = '#958e86';
//         context.stroke();

//         // Clip text inside the box
//         context.save();
//         context.rect(x, y, nodeWidth, nodeHeight);
//         context.clip();

//         context.font = `${Math.min(12 * boxScale, 12)}px Arial`;
//         context.fillStyle = 'black';

//         // Render text within padding limits, clipping at box boundary without ellipsis
//         context.fillText(node, x + 10, y + nodeHeight / 2 + 5);
//         context.restore();

//         // Tooltip for full box content
//         canvasRef.current.addEventListener('mousemove', (e) => {
//           const rect = canvas.getBoundingClientRect();
//           const mouseX = e.clientX - rect.left;
//           const mouseY = e.clientY - rect.top;

//           if (
//             mouseX >= x &&
//             mouseX <= x + nodeWidth &&
//             mouseY >= y &&
//             mouseY <= y + nodeHeight
//           ) {
//             canvas.title = node; // Tooltip shows full content of the box
//           } else if (canvas.title === node) {
//             canvas.title = ''; // Remove tooltip when not hovering
//           }
//         });
//       });
//     });
//   }, [data, dimensions, status]);

//   // Render the component
//   return (
//     <div className="p-4 bg-white bg-opacity-80">
//       <h1 className="h1 banner">Happy Path</h1>
//       <InfoBox
//         title={"YourBiz's Happy Path"}
//         description={
//           'The shortest route, that the most people take, to your most desired outcome'
//         }
//       />
//       {status === 'loading' && <div>Loading...</div>}
//       {status === 'error' && <div>Error: {error.message}</div>}
//       {status === 'success' && <canvas ref={canvasRef}></canvas>}
//       <div>
//         <SEO
//           title="Happy Path"
//           description="The fastest routes through your product to get to activation."
//           url="/happypath"
//         />
//       </div>
//     </div>
//   );
// };

// export default HappyPath;
