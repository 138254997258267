import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useDebouncedResize from './ScreenResized'; // Import your custom hook

export const DimensionContext = React.createContext();

export const ResponsiveWrapper = ({ children }) => {
  const mainContentRef = useRef(null);
  const [mainContentStyle, setMainContentStyle] = useState({});

  // Use your custom hook to get updated window dimensions
  const { width: viewportWidth, height: viewportHeight } = useDebouncedResize(300);

  useEffect(() => {
    if (mainContentRef.current && mainContentRef.current.parentNode) {
      const parent = mainContentRef.current.parentNode;

      const sidebarElement = parent.querySelector('.sidebar-container');
      let sidebarWidth = sidebarElement ? sidebarElement.offsetWidth : 0;

      // Fallback: If sidebarWidth is 0, set it to a default value if needed
      if (sidebarWidth === 0) {
        sidebarWidth = viewportWidth * 0.25; // Example: 25% of viewportWidth
      }

      const mainContentWidth = viewportWidth - sidebarWidth;
      // const mainContentWidth = viewportWidth;
      const mainContentHeight = viewportHeight;

      setMainContentStyle({
        width: mainContentWidth,
        height: mainContentHeight,
      });
    }
  }, [viewportWidth, viewportHeight, mainContentRef]);

  return (
    <DimensionContext.Provider value={mainContentStyle}>
      <div
        ref={mainContentRef}
        style={{
          width: `${mainContentStyle.width}px`,
          height: `${mainContentStyle.height}px`,
        }}
        className="responsivewrapper"
      >
        {children}
      </div>
    </DimensionContext.Provider>
  );
};

ResponsiveWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
