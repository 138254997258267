//useD3Graph.jsx
import { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

console.log("useD3Graph load");



const useD3Graph = ({
  svgRef,
  dimensions,
  status,
  graphNodes,
  graphEdges,
  sources,
  targets,
  selectedNodeIds,
  handleNodeClick,
  UniqueURL,
  UniqueEventDescList,
  nodesRef, // Pass refs if needed
  linksRef,
  simulationRef,
  shortestPath,
}) => {
  // const simulationRef = useRef();
  // const nodesRef = useRef([]);
  // const linksRef = useRef([]);
  const rScaleRef = useRef(null);
  // const dimensions = useContext(DimensionContext);
  // const svgRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [zoomTransform, setZoomTransform] = useState(d3.zoomIdentity);
  const [nodesState, setNodesState] = useState([]);
  const [pathLinksSet, setPathLinksSet] = useState(new Set());

  // Step 1: Build pathLinksSet from shortestPath
  useEffect(() => {
    if (shortestPath.length < 2) {
      // If shortestPath is empty or has only one node, clear the Set
      setPathLinksSet(new Set());
      console.log('Shortest path is too short. Clearing pathLinksSet.');
      return;
    }
    console.log('shortestPath:', shortestPath);

    const newPathLinksSet = new Set();
    for (let i = 0; i < shortestPath.length - 1; i++) {
      const source = shortestPath[i];
      const target = shortestPath[i + 1];
      newPathLinksSet.add(`${source}->${target}`);
      newPathLinksSet.add(`${target}->${source}`); // Include reverse if undirected
    }
    setPathLinksSet(newPathLinksSet);

    // Debugging: Verify the Set
    console.log('pathLinksSet:', newPathLinksSet);
    console.log('Is pathLinksSet a Set?', newPathLinksSet instanceof Set);
  }, [shortestPath]);

  // Step 2: Helper function using the Set
  function isLinkInShortestPath(link, pathLinksSet) {
    if (!(pathLinksSet instanceof Set)) {
      console.warn('pathLinksSet is not a Set:', pathLinksSet);
      return false;
    }
    
    // Debugging: Verify the Set
    console.log('pathLinksSet:', pathLinksSet);
    console.log('Is pathLinksSet a Set?', pathLinksSet instanceof Set); // Should log: true

    const sourceId = link.source.id || link.source; // Adjust based on link structure
    const targetId = link.target.id || link.target;
    const linkId = `${sourceId}->${targetId}`;
    
    const isInPath = pathLinksSet.has(linkId);
    console.log(`pathLinksSet: Link ${linkId} is in shortest path: ${isInPath}`);
    
    return isInPath;
  }

  useEffect(() => {
    const svg = d3.select(svgRef.current);
  
    // Select all link elements
    const linkSelection = svg.selectAll('.link');
  
    // Update link styles based on pathLinksSet
    linkSelection
      .transition() // Optional: Adds a smooth transition
      .duration(500)
      .attr('stroke', d => {
        if (pathLinksSet.size === 0) {
          return '#2c294b'; // Default black color
        }
        return isLinkInShortestPath(d, pathLinksSet) ? '#c5316a' : '#dbdbde'; // Pink or gray
      })
      .attr('stroke-width', d => {
        if (pathLinksSet.size === 0) {
          return 1; // Default stroke width
        }
        return isLinkInShortestPath(d, pathLinksSet) ? 8 : 1; // Thicker stroke for path links
      });
  }, [pathLinksSet]); // Dependency array ensures this runs when pathLinksSet changes
  
  
  useEffect(() => {

    if (status !== 'success' || !sources ) {
      console.log("Data not ready yet.");
      return;
    }

  if (graphNodes.length === 0 || graphEdges.length === 0) {
    console.error('Edges and Nodes are not properly defined in graphData.');
    return;
  }

  if (!svgRef.current) {
    console.log("SVG element not yet available.");
    return;
  }

  console.log("useD3Graph Nodes:", graphNodes);
  console.log("useD3Graph Edges:", graphEdges);
  console.log("useD3Graph Sources:", sources);
  console.log("useD3Graph Targets:", targets);
  console.log("useD3Graph status:", status);
  console.log("useD3Graph UniqueURL:", UniqueURL);
  console.log("useD3Graph UniqueEventDescList:", UniqueEventDescList);

  // Deep copy of links and nodes
  const nodes = graphNodes.map(d => ({ ...d }));
  const links = graphEdges.map(d => ({
    source: nodes.find(node => node.id === d.source),
    target: nodes.find(node => node.id === d.target),
  }));
  
  // Create SVG container
  const svg = d3.select(svgRef.current)
    .attr('width', dimensions.width)
    .attr('height', dimensions.height)
    .attr('viewBox', [0, 0, dimensions.width, dimensions.height])
    .attr('style', 'max-width: 100%; height: auto;');

// Select or append a 'g' element to the SVG only once
const zoomedContainer = svg.select('g').empty() ? svg.append('g') : svg.select('g');

// function isLinkInShortestPath(link, shortestPath) {
//   console.log('Link object:', link); // Inspect the structure of link

//   for (let i = 0; i < shortestPath.length - 1; i++) {
//     const fromNode = shortestPath[i];
//     const toNode = shortestPath[i + 1];
    
//     const condition1 = link.source.id === fromNode && link.target.id === toNode;
//     const condition2 = link.source.id === toNode && link.target.id === fromNode;
    
//     console.log(`Checking link ${link.source.id} - ${link.target.id}: ${condition1 || condition2}`);
    
//     if (condition1 || condition2) {
//       return true;
//     }
//   }
//   return false;
// }


// // Step 1: Create a Set of path links for efficient lookup
// const pathLinksSet = new Set();
// for (let i = 0; i < shortestPath.length - 1; i++) {
//   const source = shortestPath[i];
//   const target = shortestPath[i + 1];
//   pathLinksSet.add(`${source}->${target}`);
//   pathLinksSet.add(`${target}->${source}`); // Include reverse if undirected
// }


  // linksRef.current = links
  const link = zoomedContainer.selectAll('.link') // Select links within the zoomed group
    // .data(linksRef.current)
    .data(links)
    .enter()
    .append('line')
    .attr('class', 'link')
    .attr('stroke', d => {
      if (pathLinksSet.size === 0) {
        return '#2c294b'; // Default black color when no path is selected
      }
      return isLinkInShortestPath(d, pathLinksSet) ? '#c5316a' : '#ebe5df'; // Pink for path links, gray otherwise
    })
    .attr('stroke-opacity', 0.6)
    .attr('stroke-width', d => {
      if (pathLinksSet.size === 0) {
        return 1; // Default stroke width
      }
      return isLinkInShortestPath(d, pathLinksSet) ? 8 : 1; // Thicker stroke for path links
    });
    const labelDiv = d3.select('#label');

    linksRef.current = links;

    console.log("UniqueURL:", UniqueURL);
    console.log("UniqueEventDescList:", UniqueEventDescList);

const counts = graphNodes
    .filter((node) => node.id !== "begin" && node.id !== "end" && node.id !== "Empty" && node.id !== undefined)
    .map((node) => {
    return UniqueURL[node.id] || UniqueEventDescList[node.id] || 0;
    });

const minCount = Math.min(...counts);
const maxCount = Math.max(...counts);
const domain = minCount === maxCount ? [0, maxCount] : [minCount, maxCount];

console.log('MinCount:', minCount);
console.log('maxCount:', maxCount);

rScaleRef.current = d3.scaleLinear()
    .domain(domain)
    .range([5, 70]);  // Range of circle radius, adjust as needed

console.log('Counts:', counts);

// Update nodesRef with the new nodes data
nodesRef.current = [...nodes];
// Create the D3 selection\


const node = zoomedContainer.selectAll('.node')
.data(nodesRef.current, d => d ? d.id : null)
.join(
enter => enter.append('circle').attr('class', 'node'),
update => update,
exit => exit.remove()
)
  .attr('class', d => `node ${selectedNodeIds.includes(d.id) ? 'selected-node' : ''}`)
  .attr('stroke', '#7174b0')
  .attr('stroke-width', 1.5)
  .attr('r', d => {
    const count = UniqueURL[d.id] || UniqueEventDescList[d.id] || 0;
    // console.log(`Node ID: ${d.id}, Count: ${count}`);
    return rScaleRef.current(count);
  })    
  .attr('stroke', d => shortestPath.includes(d.id) ? '#c5316a' : 'none' )
  .attr('stroke-width', d => shortestPath.includes(d.id) ? 1.5 : 0)
  .attr('fill', '#3b3484')
  .on('click', (event, d) => {
    if(linksRef.current && linksRef.current.length > 0) {
      console.log('LinksRef at the time of click:', linksRef.current);
      handleNodeClick(event, d, linksRef.current);
    } else {
      console.error('Links data is not available or not populated yet');
    }
  })

  .call(d3.drag()
      .on('start', dragstarted)
      .on('drag', dragged)
  .on('end', dragended))

// Add titles to the circle elements
node.append('title').text(d => d.id);

node.attr('fill', d => {
if (selectedNodeIds.length === 2) {
  return selectedNodeIds.includes(d.id) ? '#c5316a' : '#ebe5df';
}
return '#3b3484';
});

// Exit old nodes
node.exit().remove();

console.log("Updated nodesRef.current: ", nodesRef.current);


function ticked() {
link
  .attr('x1', d => d.source.x)
  .attr('y1', d => d.source.y)
  .attr('x2', d => d.target.x)
  .attr('y2', d => d.target.y);

node
  .attr('cx', d => d.x)
  .attr('cy', d => d.y);
}

const nodeIds = new Set(nodesRef.current.map(d => d.id));

console.log("nodeIds:", nodeIds);
console.log("Nodes:", nodesRef.current.map(d => d.id));
console.log('Links:', links);
console.log('LinksRef:', linksRef);

// Check if each source and target exists in nodes
links.forEach((link, index) => {
const sourceExists = nodesRef.current.some(node => node.id === link.source.id);
const targetExists = nodesRef.current.some(node => node.id === link.target.id);

if (!sourceExists || !targetExists) {
console.log(`Invalid link at index ${index}: `, link);
}
});

console.log("Using nodesRef.current in simulation 5: ", nodesRef.current);

// plan b
if (simulationRef.current) {
console.log('Is simulation an instance of d3.forceSimulation: ', simulationRef.current instanceof d3.forceSimulation);

simulationRef.current.nodes(nodesRef.current);

simulationRef.current.force('link').links(links);
simulationRef.current.alpha(1).restart();  // Reheat and restart the simulation
console.log("Is simulation an instance of d3.forceSimulation: ", simulationRef.current instanceof d3.forceSimulation);

if (!simulationRef.current.force('charge')) {
  console.error("Charge force is not defined");
}

console.log("Forces before simulation start:", {
  link: simulationRef.current.force('link'),
  charge: simulationRef.current.force('charge'),
  center: simulationRef.current.force('center')
});

} else {
console.log("at Simulation : links", links)
console.log("at Simulation : nodesRef.current", nodesRef.current)  // Shouldn't be empty now
simulationRef.current = d3.forceSimulation(nodesRef.current)  // Initialize with nodesRef.current
.force('link', d3.forceLink(links).id(d => d.id).distance(50))
.force('charge', d3.forceManyBody().strength(-15))
.force("center", d3.forceCenter(dimensions.width / 2, (dimensions.height / 2 - 20)))
.on('tick', ticked);
}
console.log("Type of charge force", typeof simulationRef.current.force('charge'));
console.log("Value of charge force", simulationRef.current.force('charge'));

console.log("NodesRed after simulation 6:", nodesRef.current);

console.log("log all methods force('charge'",Object.keys(simulationRef.current.force('charge')));

// simulationRef.current.force('charge', d3.forceManyBody().strength(-50));

console.log("log all methods force('charge'",Object.keys(simulationRef.current.force('charge')));

console.log("Is charge an instance of d3.forceManyBody: ", simulationRef.current.force('charge') instanceof d3.forceManyBody);

const charge = simulationRef.current.force('charge');

console.log("log all methods force('charge'):", Object.keys(charge));
console.log("Is charge an instance of d3.forceManyBody: ", charge instanceof d3.forceManyBody);

console.log('charge prototype:', Object.getPrototypeOf(charge));
console.log('d3.forceManyBody prototype:', Object.getPrototypeOf(d3.forceManyBody()));
console.log('Are they equal:', Object.getPrototypeOf(charge) === Object.getPrototypeOf(d3.forceManyBody()));

  // Create text elements for labels on edges
  const linkLabels = zoomedContainer.selectAll('.link-label')
    .data(links)
    .enter()
    .append('text')
    .text(d => `from ${d.source.id} to ${d.target.id}`)
    .attr('class', 'link-label')
    .style('visibility', 'hidden');

  // Create text elements for labels on nodes
  const nodeLabels = zoomedContainer.selectAll('.node-label')
    .data(nodes)
    .enter()
    .append('text')
    .text(d => d.id)
    .attr('class', 'node-label')
    .style('visibility', 'hidden')
    .attr('x', d => d.x) // Position relative to node's scaled position
    .attr('y', d => d.y - rScaleRef.current(UniqueURL[d.id] || UniqueEventDescList[d.id] || 0) - 5); // Adjust position

  function dragstarted(event) {
    if (!event.active) simulationRef.current.alphaTarget(0.3).restart();
    event.subject.fx = event.subject.x;
    event.subject.fy = event.subject.y;
  }

  function dragged(event) {
    event.subject.fx = event.x;
    event.subject.fy = event.y;
  }

  function dragended(event, d) {
    if (!event.active) simulationRef.current.alphaTarget(0);
    d.fx = null;
    d.fy = null;
  
    // Only stopPropagation if the node was actually dragged
    if (event.sourceEvent.type === 'drag') {
      event.sourceEvent.stopPropagation();
    }
  }
  
  node.on('mouseenter', function (event, d) {
    d3.select(this).attr('r', rScaleRef.current(UniqueURL[d.id] || UniqueEventDescList[d.id] || 0) + 2);
    nodeLabels.filter(labelData => labelData.id === d.id)
      .style('visibility', 'visible');
    labelDiv.text(`Node: ${d.id}`);

  })
  .on('mouseleave', function (event, d) {
    d3.select(this).attr('r', rScaleRef.current(UniqueURL[d.id] || UniqueEventDescList[d.id] || 0));
    nodeLabels.filter(labelData => labelData.id === d.id)
      .style('visibility', 'hidden');
    labelDiv.text('');

  })
  .on('click', (event, d) => {
    if(linksRef.current && linksRef.current.length > 0) {
      console.log('LinksRef at the time of click:', linksRef.current);
      handleNodeClick(event, d, linksRef.current);
    } else {
      console.error('Links data is not available or not populated yet');
    }
  });
  
  link.on('mouseenter', function (event, d) {
    labelDiv.text(`Link from ${d.source.id} to ${d.target.id}`);
  })
    .on('mouseleave', function () {
      labelDiv.text('');
    });

    
    // Remove existing SVG labels (if any)
    zoomedContainer.selectAll('.node-label').remove();
    zoomedContainer.selectAll('.link-label').remove();

    setIsLoading(false);

    // Clean-up function
  return () => simulationRef.current.stop();
}, [status, graphNodes, graphEdges, dimensions, UniqueEventDescList, UniqueURL, zoomTransform, selectedNodeIds, sources, handleNodeClick, pathLinksSet]);


useEffect(() => {
  if (!svgRef.current) {
    console.log("SVG element not yet available for updating styles.");
    return;
  }

  const svg = d3.select(svgRef.current);
  const zoomedContainer = svg.select('g.zoomable');

  // Ensure node and link selections are available
  const node = zoomedContainer.selectAll('.node');
  const link = zoomedContainer.selectAll('.link');

  // Function to update node and link styles based on shortestPath
  function updateStyles() {
    if (!shortestPath || shortestPath.length === 0) {
      // Reset styles if there's no shortest path
      node.attr('fill', d => {
        if (selectedNodeIds.length === 2 && selectedNodeIds.includes(d.id)) {
          return '#c5316a'; // Color for selected nodes
        } else {
          return '#3b3484'; // Default node color
        }
      });

      link.attr('stroke', '#2c294b'); // Default link color
      return;
    }

    // Highlight nodes in the shortest path
    node.attr('fill', d => {
      if (shortestPath.includes(d.id)) {
        return 'red'; // Highlight color
      } else if (selectedNodeIds.length === 2 && selectedNodeIds.includes(d.id)) {
        return '#c5316a'; // Color for selected nodes
      } else {
        return '#3b3484'; // Default node color
      }
    });

    // Create a set of node pairs representing the shortest path links
    const pathLinks = new Set();
    for (let i = 0; i < shortestPath.length - 1; i++) {
      const source = shortestPath[i];
      const target = shortestPath[i + 1];
      pathLinks.add(`${source}->${target}`);
      pathLinks.add(`${target}->${source}`); // Include reverse if undirected
    }

    // Highlight links that are part of the shortest path
    link.attr('stroke', d => {
      if (
        pathLinks.has(`${d.source.id}->${d.target.id}`) ||
        pathLinks.has(`${d.target.id}->${d.source.id}`)
      ) {
        return 'red'; // Highlight color
      } else {
        return '#2c294b'; // Default link color
      }
    });
  }

  // Call the updateStyles function
  updateStyles();
}, [shortestPath]); 

  useEffect(() => {
    // Assuming svgRef is a ref to your SVG element
    if (!svgRef.current) {
      console.log("SVG element not yet available.");
      return;
    }
    console.log("SVG Element:", svgRef.current);
    const svg = d3.select(svgRef.current);
    console.log("Establishing Zoom context:");

    // Define the zoom behavior
    const zoom = d3.zoom()
      .scaleExtent([0.5, 20])
      .on('zoom', (event) => {
        // event.preventDefault(); // Prevent default zooming
        // Apply the zoom transform directly to the zoomedContainer
        const zoomedContainer = svg.select('g.zoomable');
        zoomedContainer.attr('transform', event.transform);
        // console.log("Zoom event:", event.transform);
      });
  
    // Apply the zoom behavior to the SVG element
    svg.call(zoom);
  
    // Cleanup function to remove zoom behavior when the component is unmounted
    return () => {
      svg.on('.zoom', null);
    };
  }, [status, sources, graphNodes, graphEdges]);
  

  useEffect(() => {
    // When nodesRef.current is populated, update nodesState to trigger a re-render
    if (nodesRef.current.length > 0) {
      setNodesState(nodesRef.current);
    }
  }, [nodesRef.current.length]); // Trigger only when nodesRef.current.length changes

  useEffect(() => {
    console.log('Current nodesState in LinkRef create:', nodesState);
  
    if (nodesState && nodesState.length > 0) {
      // Calculate weights and find min and max weights
      let minWeight = Infinity;
      let maxWeight = -Infinity;
  
      const linksWithWeights = graphEdges.map(d => {
        const sourceNode = nodesState.find(node => node.id === d.source);
        const targetNode = nodesState.find(node => node.id === d.target);
  
        let weight = Math.sqrt(
          Math.pow(targetNode.x - sourceNode.x, 2) +
          Math.pow(targetNode.y - sourceNode.y, 2)
        );
        // console.log('sourceNode', sourceNode);
        // console.log('targetNode', targetNode);
        // console.log('weight', weight);

        minWeight = Math.min(minWeight, weight);
        maxWeight = Math.max(maxWeight, weight);
  
        return {
          ...d,
          source: sourceNode,
          target: targetNode,
          weight: weight
        };
      });
  
      // Normalize weights to a 1-5 scale
      const normalizedLinks = linksWithWeights.map(link => {
        const distancerating = 1 + 4 * (link.weight - minWeight) / (maxWeight - minWeight);
        return {
          ...link,
          distancerating: Math.round(distancerating), // Round to the nearest integer
          weight: Math.round(link.weight) // Round to the nearest integer
        };
      });
  
      linksRef.current = normalizedLinks;
      console.log('After populating LinksRef - linksRef.current:', linksRef.current);
    }
  }, [nodesState]);
  

  // Return any refs or data you need in your component
  return {
    simulationRef,
    nodesRef,
    linksRef,
    rScaleRef,
  };
};

export default useD3Graph;
