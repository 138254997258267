import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import AStarPath from './AStarPath';
import { DimensionContext } from '../ResponsiveWrapper';
import D3Graph from './D3GraphRend'; // Import D3Graph

console.log("NodeClick load");

const NodeClick = ({ 
  status,
  graphNodes,
  graphEdges,
  sources,
  targets,
  UniqueURL,
  UniqueEventDescList,
  onNodeDataChange,
}) => {
    const simulationRef = useRef();
    const [selectedNodeIds, setSelectedNodeIds] = useState([]);
    const [nodeBoxContent1, setNodeBoxContent1] = useState({ title: '', description: '' });
    const [nodeBoxContent2, setNodeBoxContent2] = useState({ title: '', description: '' });
    const [isNodeBox1Visible, setIsNodeBox1Visible] = useState(false);
    const [isNodeBox2Visible, setIsNodeBox2Visible] = useState(false);
    const dimensions = useContext(DimensionContext);
    const nodesRef = useRef([]);
    const linksRef = useRef([]);
    const svgRef = useRef();
    const [shortestPath, setShortestPath] = useState([]);

    useEffect(() => {
        if (onNodeDataChange) {
          onNodeDataChange({
            nodeBoxContent1,
            nodeBoxContent2,
            isNodeBox1Visible,
            isNodeBox2Visible,
          });
        }
      }, [nodeBoxContent1, nodeBoxContent2, isNodeBox1Visible, isNodeBox2Visible]);

    const handleNodeClick = useCallback((event, clickedNode, links) => {
        console.log('onClick fired with node:', clickedNode.id);
    
        const simulation = simulationRef.current;
        const nodes = nodesRef.current;
    
        if (!simulation || !nodes) {
        console.error('Simulation or nodes are not initialized');
        return;
        }
    
        // Helper to fix node position
        const fixNodePosition = (node, x, y) => {
        console.log(`Fixing node position for node ${node.id} to [${x}, ${y}]`);
        node.fx = x;
        node.fy = y;
        };
    
        // Helper to release node position
        const releaseNodePosition = (node) => {
        console.log(`Releasing node position for node ${node.id}`);
        node.fx = null;
        node.fy = null;
        };
    
        // Helper to restart simulation
        const restartSimulation = (alpha = 0.3) => {
        console.log(`Restarting simulation with alpha: ${alpha}`);
        simulation.alpha(alpha).restart();
        };
    
        // Safe check before using 'links'
        if (!Array.isArray(links)) {
        console.error('Invalid or undefined links data');
        return;
        }
        console.log('Just links:', links);
        console.log('Just linksRed:', linksRef);
        console.log('Just linksRed.current:', linksRef.current);
    
        const connectedLinks = links.filter(link => 
        link.source.id === clickedNode.id || link.target.id === clickedNode.id
        );
    
        const getColorForRating = (rating) => {
        switch(rating) {
            case 1: return '#2c294b'; // dkpurple - 1 stars
            case 2: return '#762861'; // cranberry - 2 star
            case 3: return '#c5316a'; // magenta - 3 stars
            case 4: return '#3b3484'; // mdpurple - 4 stars
            case 5: return '#7174b0'; // ltpurple - 5 stars
            default: return 'black'; // default color if rating is not 1-5
        }
        };
    
        console.log('Connected links:', connectedLinks);
    
        console.log('Current simulation forces:', simulation.force('charge'), simulation.force('link'));
    
        switch (selectedNodeIds.length) {
        case 0: // First node clicked
        console.log('First node clicked:', clickedNode.id);
    
            setSelectedNodeIds([clickedNode.id]);
            fixNodePosition(clickedNode, dimensions.width - 100, 100); // Top-right corner
            const filteredLinksForFirstNode = connectedLinks.filter(link => link.source.id === clickedNode.id);
            setNodeBoxContent1({
            title: clickedNode.id,
            description: filteredLinksForFirstNode
                .sort((a, b) => a.weight - b.weight)
                .map(link => {
                const color = getColorForRating(link.distancerating);
                return (
                    <span key={link.source?.id + link.target?.id} style={{ color: color }}>
                    ({link.distancerating}) to → {link.target?.id} is {link.weight} away
                    </span>
                );
            }) || 'No connected links'
            });
            console.log('NodeBox1 set to:', nodeBoxContent1);
    
            setIsNodeBox1Visible(true);
            break;
    
        case 1: // Second unique node clicked
        console.log('Second node clicked:', clickedNode.id);
    
            if (selectedNodeIds[0] !== clickedNode.id) {
            setSelectedNodeIds([selectedNodeIds[0], clickedNode.id]);
            fixNodePosition(clickedNode, 100, dimensions.height - 100); // Bottom-left corner
            const filteredLinksForSecondNode = connectedLinks.filter(link => link.target.id === clickedNode.id);
    
            setNodeBoxContent2({
                title: clickedNode.id,
                // Assuming links is an array of link objects { source, target, ... }
                // description: links.filter(link => link.source.id === clickedNode.id || link.target.id === clickedNode.id)
                description: filteredLinksForSecondNode
                .sort((a, b) => a.weight - b.weight) // Sort links by weight in ascending order
                .map(link => {
                const color = getColorForRating(link.distancerating);
                return (
                    <span key={link.source?.id + link.target?.id} style={{ color: color }}>
                    ({link.distancerating}) from → {link.source?.id} is {link.weight} away
                    </span>
                )
                // return `• ${link.source?.id} → ${link.target?.id} || ${link.weight}`;
                // }).join("\n")
            })
            });
            console.log('NodeBox2 set to:', nodeBoxContent2);
            console.log('AStarPath links:', links);
            console.log('AStarPath linksRef:', linksRef);
            console.log('AStarPath linksRef.current:', linksRef.current);
            const path = AStarPath(linksRef, selectedNodeIds[0], clickedNode.id);
            console.log('AStarPath Path set to:', path);
            setShortestPath(path);

            setIsNodeBox2Visible(true);
            // setTimeout(() => console.log('setIsNodeBox2Visible set to:', isNodeBox2Visible), 0);
            } else {
            // Clicked on the same node again, hide NodeBox and release node
            console.log('Same node clicked again:', clickedNode.id);
            console.log('Changing NodeBox1 to False');
            setIsNodeBox1Visible(false);
            setTimeout(() => console.log('setIsNodeBox1Visible set to:', isNodeBox1Visible), 0);
            releaseNodePosition(clickedNode);
            setSelectedNodeIds([]);
            }
            break;
    
        case 2: // Third click, regardless of the node
        console.log('Third click detected, clearing selections and NodeBoxes');
    
            selectedNodeIds.forEach(id => {
            const node = nodes.find(d => d.id === id);
            releaseNodePosition(node);
            });
            setSelectedNodeIds([]);
            console.log('Changing NodeBox1 to False');
            setIsNodeBox1Visible(false);
            // console.log('setIsNodeBox1Visible set to:', isNodeBox1Visible);
            console.log('Changing NodeBox2 to False');
            setIsNodeBox2Visible(false);
            // console.log('setIsNodeBox2Visible set to:', isNodeBox2Visible);
            setShortestPath([]);

            break;
    
            default:
            // No default action required, or add any default logic if necessary
            console.log('No additional action needed for the click event');
        }
            // Log final node positions and pinned states
            console.log('Node positions and fixed states after click:', nodes.map(d => ({
            id: d.id, 
            x: d.x, 
            y: d.y, 
            fx: d.fx, 
            fy: d.fy
            })));
    
        restartSimulation();
    }, [selectedNodeIds, dimensions, nodeBoxContent1, nodeBoxContent2, isNodeBox1Visible, isNodeBox2Visible]);
  


  useEffect(() => {
    console.log('NodeBox1 updated:', nodeBoxContent1);
  }, [nodeBoxContent1]);
  
  useEffect(() => {
    console.log('NodeBox2 updated:', nodeBoxContent2);
  }, [nodeBoxContent2]);
  
  useEffect(() => {
    console.log('NodeBox1 Visibility:', isNodeBox1Visible);
  }, [isNodeBox1Visible]);
  
  useEffect(() => {
    console.log('NodeBox2 Visibility:', isNodeBox2Visible);
  }, [isNodeBox2Visible]);


  return (
    
        <D3Graph
      status={status}
      graphNodes={graphNodes}
      graphEdges={graphEdges}
      sources={sources}
      targets={targets}
      UniqueURL={UniqueURL}
      UniqueEventDescList={UniqueEventDescList}
      selectedNodeIds={selectedNodeIds}
      handleNodeClick={handleNodeClick}
      nodesRef={nodesRef} 
      linksRef={linksRef}
      simulationRef={simulationRef}
      svgRef={svgRef}
      shortestPath={shortestPath} 

    />
  );
};

export default NodeClick;